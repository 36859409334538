body {
    font-family: sans-serif;
    font-size: 12pt;
    background-color: #fbfbfb;
}

select,
#userForm>input {
    width: 300px;
}

label {
    display: inline-block;
    margin-top: 20px;
}

a {
    text-decoration: none;
    color: #4069b6;
}

a:hover {
    text-decoration: underline;
    color: #1e4691;
}

table {
    padding: 0px;
    border-spacing: 0;
    border-collapse: collapse;
}

th {
    padding: 10px;
    border: 1px solid gray;
    background-color: rgb(67, 69, 190);
    color: lightgray;
    position: sticky;
    top: 0;
}

td {
    padding: 5px;
    border: 1px solid gray;
}

.graycell {
    background-color: lightgray;
}

.greencell {
    background-color: rgb(146, 237, 155);
}

.content_div {
    min-width: 350px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.content_block {
    margin-top: 40px;
    width: 400px;
}

.header_link {
    text-decoration: none;
    color: black;
}

.main_div {
    margin: 0px 20px 0px 20px;
}

.center {
    text-align: center;
    width: 100%;
}

.left {
    text-align: left;
    width: 100%;
}

.notification {
    position: absolute;
    top: 70px;
    right: 30px;
    padding: 10px;
    border: 1px solid;
    border-color: lightgray;
    border-radius: 10px;
    background-color: lightyellow;
    opacity: 0.7;
    transition: opacity 2s;
}

.transparent {
    opacity: 0;
}

.greennote {
    color: #228800;
    transition: color 1s;
}

.greynote {
    color: darkgrey;
    transition: color 1s;
}

.rednote {
    color: #991111;
    transition: color 1s;
}


.bold {
    font-weight: bold;
}

.noscroll {
    overflow: hidden;
}

.adminContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: fit-content;
}

.addButton {
    width: 100%;
    text-align: right;
    margin: 10px 0 10px 0;
}