.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgb(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s all;
}

.modal.active {
    opacity: 1;
    pointer-events: all;
    overflow: scroll;
}

.modalContent {
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    transform: scale(0.5);
    transition: 0.3s all;
    overflow: scroll;
    max-height: calc(100% - 40px);
    max-width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modalContent.active {
    transform: scale(1);
}

.modalTitle {
    display: flex;
    flex-direction: row;
}

.close {
    color: #000000;
    font-size: 24px;
    text-align: right;
    cursor: pointer;
}
